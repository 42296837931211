import classnames from 'classnames';
import type { FunctionComponent } from 'react';

import { CheckCircle } from '@/core/tamagoshi/icons/CheckCircle';
import { Text } from '@/core/tamagoshi/Text/Text';

import styles from './InStock.module.scss';

export interface InStockProps {
  label: string;
  className?: string;
}

export const InStock: FunctionComponent<InStockProps> = ({
  label,
  className,
}) => {
  const rootClassnames = classnames(styles.root, className);

  return (
    <Text tag="span" type="small" className={rootClassnames}>
      <CheckCircle className={styles.icon} width={14} height={14} />
      <span className={styles.label}>{label}</span>
    </Text>
  );
};

InStock.displayName = 'InStock';
