export const generateUrl = (
  {
    url: productSlug,
    modelId,
    articleId,
    modelsCount = 0,
    productId,
  }: {
    url: string;
    modelId: number;
    articleId: number;
    modelsCount?: number;
    productId?: string;
  },
  isPromoted = false,
  isSponsored = false,
) => {
  const baseURL = `${productSlug}-${articleId}`;

  if (isSponsored && productId) {
    return `${baseURL}?product_id=${productId}`;
  }

  if (
    modelsCount > 1 ||
    isPromoted ||
    // if there is no model count, we force the modelId when it's different
    // than the article Id to go to the right product
    (modelsCount === 0 && modelId !== articleId)
  ) {
    return `${baseURL}?model_id=${modelId}`;
  }
  return baseURL;
};

export function mapTaxToProductCardTax(taxKey: 'without_vat' | 'with_vat') {
  const taxMap = {
    without_vat: 'withoutVAT',
    with_vat: 'VAT',
  };
  return taxMap[taxKey] as 'withoutVAT' | 'VAT';
}
