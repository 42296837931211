import {
  Svg,
  type SvgProps,
} from '@manomano-internal/tamagoshi-icons/esm/SvgOptimizer/Svg';
import { useId, type FunctionComponent } from 'react';

export interface ManomanoNutLogoProps extends SvgProps {
  className?: string;
  'data-testid'?: string;
  height?: string;
  theme?: 'dark' | 'light';
  title?: string;
  width?: string;
}

export const ManomanoNutLogo: FunctionComponent<ManomanoNutLogoProps> = (
  props,
) => {
  const id = `b2cgradient-${useId()}`;

  return (
    <Svg optimizationId="iconsManomanoNut" viewBox="0 0 42 40" {...props}>
      <>
        <path
          d="M41.068 14.958L29.978 1.31A3.56 3.56 0 0026.664.044L9.282 2.81a3.55 3.55 0 00-2.755 2.234L.234 21.456a3.541 3.541 0 00.56 3.5l11.089 13.659A3.559 3.559 0 0015.2 39.87l17.382-2.77a3.542 3.542 0 002.755-2.23l6.293-16.413a3.541 3.541 0 00-.562-3.5z"
          fill={`url(#${id})`}
        />
        <path
          d="M25.73 11.768L21.794 18.8l-3.868-8.348-4.83 1.286-5.982 15.569h5.336l3.6-9.182 3.546 8.745h2.732l5.247-9.373v11.992l4.979-1.68v-14.83l-6.824-1.21z"
          fill="#fff"
        />
        <defs>
          <linearGradient
            id={id}
            x1="17.29"
            y1="42.343"
            x2="39.452"
            y2="22.109"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#0397A7" />
            <stop offset=".997" stopColor="#00ECCD" />
          </linearGradient>
        </defs>
      </>
    </Svg>
  );
};

ManomanoNutLogo.displayName = 'ManomanoNutLogo';
