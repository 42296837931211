import { isGermanyPlatform } from '@/core/settings/utils';
import { type Product } from '@/productDiscovery/algolia/interfaces/product';
import { type DeliveryLabelProps } from '@/productDiscovery/Listings/components/ProductCardListingsEnhancer/components/ProductCardListings/components/DeliveryLabel/DeliveryLabel';

export const getDelivery = (
  product: Pick<Product, 'hasFreeDelivery' | 'isMmf'>,
): DeliveryLabelProps['deliveryLabel'] | undefined => {
  const { hasFreeDelivery, isMmf } = product;

  if (isMmf) {
    return hasFreeDelivery ? 'freeManoExpressDelivery' : 'manoExpressDelivery';
  }
  if (isMmf && (hasFreeDelivery || isGermanyPlatform()))
    return 'freeDeliveryByMM';
  if (!isMmf && hasFreeDelivery) return 'freeDelivery';
  return undefined;
};
