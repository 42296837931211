import classnames from 'classnames';
import dynamic from 'next/dynamic';
import { type FunctionComponent } from 'react';

import { Text } from '@/core/tamagoshi/Text/Text';

import styles from './FreeDeliveryLabel.module.scss';

const DynamicTruckIcon = dynamic(
  () => import('@/core/tamagoshi/icons/Truck').then((mod) => mod.Truck),
  { ssr: false },
);

export interface FreeDeliveryLabelProps {
  className?: string;
  label: string;
  withIcon?: boolean;
}

export const FreeDeliveryLabel: FunctionComponent<FreeDeliveryLabelProps> = ({
  className,
  label,
  withIcon = true,
}) => {
  const rootClassNames = classnames(styles.root, className);

  return (
    <Text
      data-testid="free-delivery-label"
      className={rootClassNames}
      type="small"
      weight="semiBold"
      tag="span"
    >
      {withIcon && (
        <>
          <DynamicTruckIcon
            className={styles.icon}
            width="16px"
            height="16px"
          />
          <span>{label}</span>
        </>
      )}
      {!withIcon && label}
    </Text>
  );
};

FreeDeliveryLabel.displayName = 'FreeDeliveryLabel';
